<template>
  <v-container>
    <v-tabs
      v-model="activityTab"
      background-color="transparent"
      color="cyan"
      class="custom-tab-transparent"
    >
      <template v-for="(tab, index) in tabs">
        <v-tab
          class="font-size-16 font-weight-600 px-8"
          :key="index"
          :href="'#' + tab.key"
          :disabled="tab.disabled"
        >
          <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
            <!--begin::Svg Icon-->
            <inline-svg
              :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
            />
            <!--end::Svg Icon-->
          </span>
          {{ tab.title }}
        </v-tab>
      </template>
      <v-tabs-items v-model="activityTab">
        <template v-for="(tab, index) in tabs">
          <v-tab-item :value="tab.key" :key="index">
            <component :is="tab.template" :type.sync="tab.key" />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ActivityLog from "@/view/pages/partials/Activity-Log";

export default {
  name: "Activity",
  data() {
    return {
      // activityTab: null,
      tabs: [
        {
          title: "User",
          key: "user",
          template: ActivityLog,
        },
        {
          title: "Customer",
          key: "customer",
          template: ActivityLog,
        },
        {
          title: "Property",
          key: "property",
          template: ActivityLog,
        },
        {
          title: "Quotation",
          key: "quotation",
          template: ActivityLog,
        },
        {
          title: "Invoice",
          key: "invoice",
          template: ActivityLog,
        },
        {
          title: "Payment",
          key: "payment",
          template: ActivityLog,
        },
        {
          title: "Job",
          key: "job",
          template: ActivityLog,
        },
        {
          title: "Visit",
          key: "visit",
          template: ActivityLog,
        },
        {
          title: "Engineer",
          key: "engineer",
          template: ActivityLog,
        },
        {
          title: "Product & Service",
          key: "product",
          template: ActivityLog,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Activity Log" }]);
  },
  computed: {
    activityTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.activityTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "user";
      },
    },
  },
};
</script>
